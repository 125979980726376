import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import countries from '../../assets/countries.json';
import { lockTheBody, unlockTheBody } from '../helpers';
import './styles.scss';

ReactModal.setAppElement('#___gatsby');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.75)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    marginTop: '20px',
  },
};

const VpDetails = ({
  isOpen,
  closeCb,
  vp,
}) => {
  const banner = vp?.banner;
  const profile_picture = vp?.profile_picture;
  const name = vp?.full_name || 'N/A';
  const title = vp?.title || '';
  const bio = vp?.bio || '';
  const city = vp?.city || '';
  const country = vp?.country_of_residence || '';
  const countryOfResidence = countries.find(c => c?.id === country);
  const address = `${city}${country ? `, ${countryOfResidence?.name}` : ''}`;

  useEffect(() => {
    lockTheBody();
    return () => unlockTheBody();
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="VpDetailsModal"
    >
      <div className="VpDetails">
        <div className="VpDetailsInner">
          <div className="Banner">
            {banner ? (
              <img className="BannerImg" src={banner} alt="profile background" />
            ) : (
              <div className="BannerImgDefault" />
            )}
          </div>
          <div className="ProfilePictureWrapper">
            {profile_picture ? (
              <img className="ProfilePicture" src={profile_picture} alt="profile" />
            ) : (
              <svg width="80" height="80" viewBox="0 0 36 36" fill="none" className="IconNoProfilePicture">
                <rect width="36" height="36" rx="18" fill="#B4B1AA"></rect>
                <path fillRule="evenodd" clipRule="evenodd" d="M18 10C16.3431 10 15 11.3431 15 13C15 14.6569 16.3431 16 18 16C19.6569 16 21 14.6569 21 13C21 11.3431 19.6569 10 18 10ZM13 13C13 10.2386 15.2386 8 18 8C20.7614 8 23 10.2386 23 13C23 15.7614 20.7614 18 18 18C15.2386 18 13 15.7614 13 13ZM12.083 26H23.917C23.441 23.1623 20.973 21 18 21C15.027 21 12.559 23.1623 12.083 26ZM10 27C10 22.5817 13.5817 19 18 19C22.4183 19 26 22.5817 26 27C26 27.5523 25.5523 28 25 28H11C10.4477 28 10 27.5523 10 27Z" fill="#E6EAEB"></path>
              </svg>
            )}
          </div>
          <div className="Content">
            <div className="Name">{name}</div>
            <div className="Title">{title}</div>
            {!!address && (
              <div className="Address">
                <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.53014 1.43016C3.17034 -0.210038 5.82963 -0.210038 7.46984 1.43016C9.11004 3.07037 9.11004 5.72966 7.46984 7.36986L4.49999 10.3397L1.53014 7.36986C-0.110063 5.72966 -0.110063 3.07037 1.53014 1.43016ZM4.49999 5.60001C5.16273 5.60001 5.69999 5.06275 5.69999 4.40001C5.69999 3.73727 5.16273 3.20001 4.49999 3.20001C3.83725 3.20001 3.29999 3.73727 3.29999 4.40001C3.29999 5.06275 3.83725 5.60001 4.49999 5.60001Z" fill="#5F5F61" />
                </svg>
                <div className="AddressText">{address}</div>
              </div>
            )}
            <div className="Section">
              <div className="title">Bio</div>
              <div className="text">{bio}</div>
            </div>
          </div>
        </div>
        <div className="close-btn">
          <button onClick={closeCb}>
            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" className="IconClose"><path fillRule="evenodd" clipRule="evenodd" d="M0.390524 0.390524C0.911223 -0.130175 1.75544 -0.130175 2.27614 0.390524L8 6.11438L13.7239 0.390524C14.2446 -0.130175 15.0888 -0.130175 15.6095 0.390524C16.1302 0.911223 16.1302 1.75544 15.6095 2.27614L9.88562 8L15.6095 13.7239C16.1302 14.2446 16.1302 15.0888 15.6095 15.6095C15.0888 16.1302 14.2446 16.1302 13.7239 15.6095L8 9.88562L2.27614 15.6095C1.75544 16.1302 0.911223 16.1302 0.390524 15.6095C-0.130175 15.0888 -0.130175 14.2446 0.390524 13.7239L6.11438 8L0.390524 2.27614C-0.130175 1.75544 -0.130175 0.911223 0.390524 0.390524Z" fill="#A19E95"></path></svg>
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

VpDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  vp: PropTypes.object.isRequired,
};

export default VpDetails;
