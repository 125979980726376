import React from 'react';
import PropTypes from "prop-types"
import './styles.scss';

const Container = ({ children, background, isSmall }) => (
  <div className="Container" style={{ backgroundColor: background }}>
    <div className={isSmall ? 'Container-content-smaller' : 'Container-content'}>
      {children}
    </div>
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  isSmall: PropTypes.bool,
}

Container.defaultProps = {
  background: '#191919',
  isSmall: false,
}

export default Container;