import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'
import { Container, VpCard, Loader } from '@components';
import { getPath, getJsonResponse } from '../../api';
import './styles.scss';

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const Team = () => {
  const [isLoading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const endpointPath = getPath('/api/v1/platform-owners');
    const config = {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
    };
    fetch(endpointPath, config)
      .then(getJsonResponse)
      .then((data) => {
        const members = data[0]?.members;
        const owners = data[0]?.owners;
        const vpList = [...owners, ...members];
        setTeamMembers(vpList || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container isSmall background="#EBF5FB">
      <div className="Team">
        <div className="header">Founders & Management Team</div>
        <div className="list">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {isLoading ? (
              <div className="LoaderOrganization"><Loader /></div>
            ) : teamMembers.map(vp => <VpCard key={vp?.profile?.user_id} vp={vp?.profile} member={vp?.member || vp?.owner} />)}
          </Masonry>
        </div>
      </div>
    </Container>
  );
};

export default Team;
