import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ProgressBar = ({
  percentage, min, color, height,
}) => {
  let width = Math.max(min, percentage);
  if (width > 100) {
    width = 100;
  }
  const progressBarWidth = `${width}%`;

  return (
    <div className="ProgressBar" style={{ height }}>
      <div className="FilledBar" style={{ width: progressBarWidth, background: color }} />
    </div>
  );
};

ProgressBar.propTypes = {
  min: PropTypes.number,
  height: PropTypes.string,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

ProgressBar.defaultProps = {
  min: 1,
  height: '4px',
  percentage: 0,
  color: '#197A48',
};

export default ProgressBar;
