import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css'
import moment from 'moment'
import { navigate } from "gatsby"
import {
  Container, Loader, EventCard,
} from '@components';
import { getPath, getJsonResponse } from '../../api';
import './styles.scss';

const breakpointColumnsObj = {
  default: 3,
  920: 2,
  600: 1
};

const UpcomingEvents = () => {
  const [eventsLoading, setEventsLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const todayDate = moment().format('yyyy-MM-DDTHH:mm:ss.SSS');
    const endpointPath = getPath(`/api/v1/offering-events?page=1&per_page=100&end_date_ge=${todayDate}&sort_by=start_date&sort_dir=asc&published=true`);
    const config = {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
    };
    fetch(endpointPath, config)
      .then(getJsonResponse)
      .then((data) => {
        const list = data?.records;
        setEvents(list || []);
        setEventsLoading(false);
      })
      .catch(() => {
        setEventsLoading(false);
      });
  }, []);

  return (
    <Container isSmall background="#fff">
      <div className="UpcomingEvents">
        <div className="list">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {eventsLoading ? (
              <div className="LoaderOrganization"><Loader /></div>
            ) : events.map(e => <EventCard key={e.id} event={e} handleCardClick={() => navigate(`/events/${e.id}`)} />)}
          </Masonry>
        </div>
      </div>
    </Container>
  );
}

export default UpcomingEvents;
