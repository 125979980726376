import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@components';
import './styles.scss';

const Document = ({ children }) => (
  <Container background="#fff">
    <div className="Document">
      {children}
    </div>
  </Container>
);

Document.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default Document;
