import React from 'react';
import { Container } from '@components';
import './styles.scss';

const Hero = () => (
  <Container>
    <div className="Hero">
      <div className="header">
        <h1>
          Connecting talent to capital
        </h1>
        <p>
          Join our investment network, invite the people you trust and reshape how capital, entrepreneurship and professional knowledge connect
        </p>
      </div>
      <div className="background-image" />
    </div>
  </Container>
);

export default Hero;
