import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, OfferingDetails } from '@components';
import noImagePlaceholder from '../../assets/placeholder-image.png';
import currencies from '../../assets/currencies.json';
import './styles.scss';

const OfferingCard = ({ offering }) => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  const name = offering?.offering.name || '';
  const description = offering?.offering.description || '';
  const offering_picture = offering?.offering.logo || '';
  const amount_raised = Number(offering?.offering.amount_raised) || 0;
  const off_platform_investments = Number(offering?.offering.off_platform_investments) || 0;
  const totalRaised = amount_raised + off_platform_investments;
  const target = Number(offering?.offering.target) || 0;
  const backgroundImg = offering_picture || noImagePlaceholder;
  const percentage = (totalRaised / target) * 100;

  const eur = currencies.find(c => c.code === 'EUR');
  const offeringCurrency = currencies.find(c => c?.id === offering?.offering.currency_id);
  const initialCurrency = offeringCurrency || eur;
  const { symbol } = initialCurrency;

  const organization = offering?.business_entity_owners?.[0];
  const organizationName = organization?.name;

  const visibility = offering?.offering.visibility;
  const isClassifiedOrganization = visibility === 'classified_business_entity';
  const isClassifiedTarget = visibility === 'classified_target';

  return (
    <>
      <button className="OfferingCard" type="button" onClick={() => setDetailsOpen(true)}>
        <div className="Banner">
          {backgroundImg ? (
            <img src={backgroundImg} className="BannerImg" alt="offering banner" />
          ) : (
            <div className="BannerImgDefault"></div>
          )}
        </div>
        <div className="CardInner">
          <div className="Name">{name}</div>
          <div className="Organization">{isClassifiedOrganization ? 'Classified organization' : organizationName}</div>
          <div className="Description">{description}</div>
          <ProgressBar percentage={percentage} />
          <div className="FundingContainer">
            {isClassifiedTarget ? (
              <div className="AmountGoal">Classified transaction</div>
            ) : (
              <>
                <div className="AmountRaised">{`${symbol} ${totalRaised.toLocaleString()} Funded`}</div>
                <div className="AmountSeparator">/</div>
                <div className="AmountGoal">{`${symbol} ${target.toLocaleString() || '-'} Goal`}</div>
              </>
            )}
          </div>
        </div>
      </button>
      {isDetailsOpen && (
        <OfferingDetails
          isOpen={isDetailsOpen}
          closeCb={() => setDetailsOpen(false)}
          offering={offering}
        />
      )}
    </>
  );
};

OfferingCard.propTypes = {
  offering: PropTypes.object.isRequired,
};

export default OfferingCard;
