import React, { useState } from 'react';
import PropTypes from "prop-types"
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { Button, Container, JoinModal } from '@components';
import Hamburger from './Hamburger';
import Logo from '@images/kobofunds-white-logo.svg';
import { getClientAppUrl } from '../../api';
import './styles.scss';

const Header = () => {
  const location = useLocation();
  const { search } = location;
  const shouldOpen = search.includes("joinModal=1");
  // const isBrowser = () => typeof window !== "undefined"
  // const hostname = !isBrowser() ? '' : window?.location?.hostname;
  // const config = getConfigData(hostname);
  const [isOpen, toggleOpen] = useState(false);
  const [displayModal, setModalDisplay] = useState(shouldOpen);
  return (
    <Container>
      <header className="Header">
        <div className="logo">
          <Link to="/">
            <img
              alt="Kobofunds logo"
              height={46}
              src={Logo}
            />
          </Link>
        </div>
        {!isOpen && (
          <div className="mobile-cta">
            <Button
              onClick={() => {
                toggleOpen(false);
                setModalDisplay(true);
              }}
            >
              Join
            </Button>
          </div>
        )}
        <nav className={isOpen ? 'menu-open' : ''}>
          <Link to="/about-us" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>About us</Link>
          <Link to="/how-it-works" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>FAQs</Link>
          <a href={getClientAppUrl()} target="_blank" rel="noreferrer noopener">
            Log in
          </a>
          <Button
            onClick={() => {
              toggleOpen(false);
              setModalDisplay(true);
            }}
          >
            Join
          </Button>
        </nav>
        <Hamburger
          isOpen={isOpen}
          toggleOpen={() => toggleOpen(!isOpen)}
        />
        {displayModal && (
          <JoinModal
            isOpen={displayModal}
            closeCb={() => setModalDisplay(false)}
          />
        )}
      </header>
    </Container>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;