// import config from './config';

export const lockTheBody = () => {
  document.body.style.overflowY = 'hidden';
  document.documentElement.style.overflowY = 'hidden';
};

export const unlockTheBody = () => {
  document.body.style.overflowY = 'unset';
  document.documentElement.style.overflowY = 'unset';
};

export const isJSON = (val) => {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseObject = (val, fallback = undefined) => {
  let data = {};

  if (!val || !isJSON(val)) {
    return fallback || data;
  }

  const result = JSON.parse(val);
  if (typeof result === 'object' && !Array.isArray(result)) {
    data = result;
  }

  return data;
};

// export const getConfigData = (hostname = '') => {
//   if (hostname.includes('kobofunds')) {
//     return config.kobofunds;
//   }
//   if (hostname.includes('dev1')) {
//     return config.dev1;
//   }
//   if (hostname.includes('dev')) {
//     return config.dev;
//   }
//   return config.dev;
// };