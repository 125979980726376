import React from 'react';
import { Container } from '@components';
import './styles.scss';

const KoboDetails = () => (
  <Container background="#fff">
    <div className="KoboDetails">
      <div className="KoboDetails-navigation">
        <a href="#why-kobo" className="item">Why Kobo</a>
        <a href="#network" className="item">The network</a>
        <a href="#invest" className="item">Invest</a>
        <a href="#raise-capital" className="item">Raise capital</a>
        <a href="#be-vp" className="item">Be a venture partner</a>
      </div>
      <section id="why-kobo" className="KoboDetails-section">
        <div className="left">
          <div className="label">Why Kobo</div>
          <h2 className="title">
            Kobo Funds is a worldwide investment network connecting entrepreneurs, investors and talented professionals.
          </h2>
        </div>
        <div className="right">
          <p>We provide <strong>entrepreneurs</strong> access to innovative funding opportunities; <strong>investors</strong> a broader range of alternative opportunities to invest their capital; and <strong>professionals</strong> the chance to turn knowledge and experience into a powerful asset.</p>
          <p>Kobo Funds is a digital ecosystem that provides capital and knowledge to <strong>groundbreaking startups</strong> and <strong>established small-mid cap companies</strong>.</p>
        </div>
      </section>
      <section id="network" className="KoboDetails-section">
        <div className="left">
          <div className="label">The network</div>
          <h2 className="title">
            Kobo Funds is an investment network based on mutual trust and affinity, and designed to be a resilient digital community.
          </h2>
        </div>
        <div className="right">
          <p>It employs our proprietary Talent-to-Capital model to channel opportunities to the right investors, creating robust syndicates.</p>
          <p>Members and their connections make contributions to the community in the following ways:</p>
          <ul>
            <li>Investing</li>
            <li>Originating investment opportunities </li>
            <li>Managing projects </li>
            <li>Inviting their most meaningful connections</li>
          </ul>
          <p>Members are encouraged to invite their trusted connections onto the platform.</p>
        </div>
      </section>
      <section id="invest" className="KoboDetails-section">
        <div className="left">
          <div className="label">Invest</div>
          <h2 className="title">
            Gain access to qualified deals alongside professional investors and Venture Partners
          </h2>
        </div>
        <div className="right">
          <p>Kobo Funds gives investors the opportunity to enjoy the massive potential gains that early-stage investors famously enjoy. In fact, over the past decade, the average US angel investor’s portfolio pocketed an average annual return of 27%.</p>
          <p>In 1977, Mike Markkula, a retired executive, financed Apple with $250,000. Before Apple went public in 1980, he owned a third of the company. At IPO, in 1980, his initial capital had grown to $203 million.</p>
          <p>Sequoia Capital made a $60 million investment in WhatsApp, the messaging app sold to Facebook in 2014 for $19 billion. At the time of the sale, Sequoia's interest was worth about $3 billion - fifty times their original investment.</p>
          <p>Kobo Funds members are backed up by highly experienced partners and professionals. Our proprietary tools screen for opportunities at various stages of the investment cycle, and every opportunity is managed by a trusted Venture Partner.</p>
          <p>With Kobo Funds every investor* can replicate those successes with a new way of accessing companies that are raising capital, working alongside investment experts and backed up by the most highly experienced Venture Partners, with the comfort of a streamlined digital investment platform.</p>
          <p>(*) qualified as professional investors according to the Directive 2014/65/EU - Markets in Financial Instruments Directive (“MiFID”)</p>
        </div>
      </section>
      <section id="raise-capital" className="KoboDetails-section">
        <div className="left">
          <div className="label">Raise capital</div>
          <h2 className="title">
            Kobo Funds means investment banking reinvented for startups and small-mid caps. 
          </h2>
        </div>
        <div className="right">
          <p>Small and mid-sized companies (SMEs) are responsible for 85% of all new jobs created in Europe. As the driving force behind economic growth they deserve a better access to capital markets.</p>
          <p>But those firms continually find that their requirements do not sit easily with the conventional valuation criteria of traditional finance: the investment is too small; company valuation is too intrinsically linked to the entrepreneur himself; the relatively early stage means the risk is too high; the entrepreneur can offer no formal guarantee.</p>
          <p>At the same time, there are enormous capital reserves: $18 trillion of global private financial wealth is available for alternative investments.</p>
          <p>Kobo Funds grants entrepreneurs access to a reliable, digitally-enabled and flexible financing platform to bridge this gap.</p>
        </div>
      </section>
      <section id="be-vp" className="KoboDetails-section">
        <div className="left">
          <div className="label">Be a venture partner</div>
          <h2 className="title">
            Kobo Funds is a network of business leaders reshaping how companies connect to capital
          </h2>
        </div>
        <div className="right">
          <p>Small and mid-sized companies need access to more than just capital. Business know-how and network are determining factors in a young firm’s success. Companies need to surround themselves with wisdom.</p>
          <p>As they grow entrepreneurs need access to fellow leaders, big thinkers to solve problems and understand the path forward.</p>
          <p>Our network is:</p>
          <ul>
            <li>Trusted - By invitation only from your most valuable connections</li>
            <li>Connected - Built on real relationships and linked to a network of 300,000+ C-level individuals</li>
            <li>Exclusive – Using selective admission criteria</li>
          </ul>
          <p>An invite to Kobo Funds opens up relationships in a vibrant community. The possibilities are limitless.</p>
        </div>
      </section>
    </div>
  </Container>
);

export default KoboDetails;
