import React from 'react';
import { Link } from 'gatsby';
import { Container } from '@components';
import Logo from '@images/kobofunds-white-small.svg';
import Facebook from '@images/social/facebook.png';
import Linkedin from '@images/social/linkedin.png';
import './styles.scss';

const Footer = () => {
  return (
    <Container>
      <footer className="Footer">
        <div className="Footer-left">
          <Link to="/">
            <img
              alt="Kobofunds small logo"
              height={46}
              src={Logo}
            />
          </Link>
          <div className="copyright">
            Copyright © 2021 Kobo Funds
          </div>
        </div>
        <div className="Footer-right">
          <div className="Footer-nav">
            <Link to="/terms" data-scroll>Terms & Conditions</Link>
            <Link to="/privacy" data-scroll>Privacy policy</Link>
            <Link to="/nda" data-scroll>Non disclosure agreement</Link>
          </div>
          <div className="Footer-social">
            <div className="label">Follow us:</div>
            <a href="https://www.facebook.com/kobofunds" target="_blank" rel="noreferrer noopener">
              <img
                alt="Facebook logo"
                height={16}
                src={Facebook}
              />
            </a>
            <a href="https://www.linkedin.com/company/kobo-funds/" target="_blank" rel="noreferrer noopener">
              <img
                alt="Linkedin logo"
                height={16}
                src={Linkedin}
              />
            </a>
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;