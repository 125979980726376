import React, { useState } from "react";
import PropTypes from 'prop-types';
import './styles.scss';

const FaqItem = ({
  title,
  text,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="FaqItem">
      <button className="FaqItem-btn" type="button" onClick={() => setOpen(!isOpen)}>
        <div className="text">{title}</div>
        <div className="icon">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 0C9.55229 0 10 0.447715 10 1V8H17C17.5523 8 18 8.44772 18 9C18 9.55229 17.5523 10 17 10H10V17C10 17.5523 9.55229 18 9 18C8.44772 18 8 17.5523 8 17V10H1C0.447715 10 0 9.55228 0 9C0 8.44772 0.447715 8 1 8H8V1C8 0.447715 8.44772 0 9 0Z" fill="#191919" />
          </svg>
        </div>
      </button>
      {isOpen && <div className="FaqItem-content">{text}</div>}
    </div>
  );
};

FaqItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default FaqItem;
