import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from '@components';
import { lockTheBody, unlockTheBody } from '../helpers';
import ReactModal from 'react-modal';
import { getPath } from '../../api';
import './styles.scss';

ReactModal.setAppElement('#___gatsby');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.75)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '500px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '24px 28px',
  },
};

const JoinModal = ({
  isOpen,
  closeCb,
}) => {
  // const isBrowser = () => typeof window !== "undefined"
  // const hostname = !isBrowser() ? '' : window?.location?.hostname;
  // const config = getConfigData(hostname);

  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nameError, setNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShow, setPasswordShow] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [bio, setBio] = useState('');
  const [bioError, setBioError] = useState('');
  const [website, setWebsite] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRolesError, setSelectedRolesError] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const [captchaSubmitted, setCaptchaSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaSubmitted) { return false; }
    if (!email) {
      setEmailError('Email is required');
      return false;
    }
    /* eslint-disable no-useless-escape */
    const emailValid = email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!emailValid) {
      setEmailError('Email is not valid');
      return false;
    }
    if (!firstName || !lastName) {
      setNameError('Full name is required');
      return false;
    }
    if (!password) {
      setPasswordError('Password is required');
      return false;
    }
    if (!bio) {
      setBioError('Professional experience info is required');
      return false;
    }
    if (!selectedRoles.length) {
      setSelectedRolesError('Select at least one role');
      return false;
    }

    setLoading(true);
    const data = {
      user_name: email,
      password,
      bio,
      website,
      first_name: firstName,
      last_name: lastName,
      role_names: selectedRoles,
      app_name: 'client',
    };
    const endpointPath = getPath('/api/v1/register');
    const fetchConfig = {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    };
    fetch(endpointPath, fetchConfig)
      .then((response) => {
        if (!response.ok) {
          // create error object and reject if not a 2xx response code
          let err = new Error("HTTP status code: " + response.status)
          err.response = response
          err.status = response.status
          throw err
      }
        setLoading(false);
        setSubmitted(true);
      })
      .catch(() => {
        setError('Error occured. Check that you have not already used this email');
        setLoading(false);
      });
    return true;
  };

  const handleRoleSelect = (value, name) => {
    setSelectedRolesError('');
    if (value) {
      const list = [...selectedRoles, name];
      setSelectedRoles(list);
    } else {
      const list = selectedRoles.filter(sr => sr !== name);
      setSelectedRoles(list);
    }
  };

  useEffect(() => {
    lockTheBody();
    return () => unlockTheBody();
  }, []);

  const onChange = (value) => {
    setCaptchaSubmitted(!!value);
  }

  if (isSubmitted) {
    return (
      <ReactModal
        isOpen={isOpen}
        style={styles}
        onRequestClose={() => { }}
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={150}
        className="JoinModalModal"
      >
        <div className="JoinModal">
          <div className="header">Join KoboFunds</div>
          <div className="submit-container">
            <div className="text">
              Your request has been sent to the platform administrator. After you have been validated, you will reveice full access.
            </div>
            <a href={process.env.CLIENT_APP_URL} target="_blank" rel="noopener noreferrer" className="platform-link">Login to the platform</a>
          </div>
          <div className="button-container">
            <Button onClick={closeCb}>
              Close
            </Button>
          </div>
        </div>
      </ReactModal>
    );
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="JoinModalModal"
    >
      <div className="JoinModal">
        <div className="header">Join KoboFunds</div>
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="TextInput">
            <input
              required={true}
              type="email"
              placeholder="Email*"
              onChange={(e) => {
                setEmail(e?.target?.value);
                setEmailError('');
              }}
              value={email}
            />
          </div>
          <div className="FullName">
            <div className="TextInput">
              <input
                required={true}
                type="text"
                placeholder="First name*"
                onChange={(e) => {
                  setFirstName(e?.target?.value);
                  setNameError('');
                }}
                value={firstName}
              />
            </div>
            <div className="TextInput">
              <input
                required={true}
                type="text"
                placeholder="Last name*"
                onChange={(e) => {
                  setLastName(e?.target?.value);
                  setNameError('');
                }}
                value={lastName}
              />
            </div>
          </div>
          <div className="TextInput Password">
            <input
              type={passwordShow ? 'text' : 'password'}
              placeholder="Choose password*"
              required={true}
              onChange={(e) => {
                setPassword(e?.target?.value);
                setPasswordError('');
              }}
              value={password}
            />
            <div className="Checkbox">
              <label>
                <input type="checkbox" name="show-password" id="show-password" checked={passwordShow} onChange={(e) => setPasswordShow(e.target.checked)} />
                <span>Show</span>
              </label>
            </div>
          </div>
          <div className="TextInput">
            <input
              placeholder="Link to a professional website"
              onChange={(e) => {
                setWebsite(e?.target?.value);
              }}
              value={website}
            />
          </div>
          <div className="TextArea">
            <textarea
              placeholder="Tell us a bit about your professional experience.*"
              required={true}
              onChange={(e) => {
                setBio(e?.target?.value);
                setBioError('');
              }}
              value={bio}
            />
          </div>
          <div>
            <ReCAPTCHA
              sitekey="6Lf_ixIeAAAAAK_K90vtGTY9UNQIy58pC15qXZ-4"
              onChange={onChange}
            />
          </div>
          {emailError && <div className="error">{emailError}</div>}
          {nameError && <div className="error">{nameError}</div>}
          {passwordError && <div className="error">{passwordError}</div>}
          {bioError && <div className="error">{bioError}</div>}
          <div className="RolesSelect">
            <div className="roles-header">Select roles:</div>
            <div className="CheckboxInput">
              <input type="checkbox" id="maker" name="maker" checked={selectedRoles.includes('maker')} onChange={(e) => handleRoleSelect(e.target.checked, 'maker')} />
              <label htmlFor="maker">Investor</label>
            </div>
            <div className="CheckboxInput">
              <input type="checkbox" id="taker" name="taker" checked={selectedRoles.includes('taker')} onChange={(e) => handleRoleSelect(e.target.checked, 'taker')} />
              <label htmlFor="taker">Entrepreneur</label>
            </div>
            <div className="CheckboxInput">
              <input type="checkbox" id="negotiator" name="negotiator" checked={selectedRoles.includes('negotiator')} onChange={(e) => handleRoleSelect(e.target.checked, 'negotiator')} />
              <label htmlFor="negotiator">Venture partner</label>
            </div>
          </div>
          {selectedRolesError && <div className="error">{selectedRolesError}</div>}
          <div className="button-container">
            <Button onClick={handleSubmit} type="submit" disabled={isLoading || !captchaSubmitted}>
              Submit
            </Button>
          </div>
          <div className="acknowledge-container">
            <p>By clicking Submit you agree to Kobo Funds platform <a target="_blank" rel="noreferrer noopener" href="/terms" data-scroll>Terms & Conditions</a>, <a target="_blank" rel="noreferrer noopener" href="/privacy" data-scroll>Privacy policy</a> and <a target="_blank" rel="noreferrer noopener" href="/nda" data-scroll>Non disclosure agreement</a> of all the Confidential Information received through the platform.</p>
          </div>
          {error && <div className="error">{error}</div>}
        </form>
        <div className="close-btn">
          <button onClick={closeCb} disabled={isLoading}>
            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" className="IconClose"><path fillRule="evenodd" clipRule="evenodd" d="M0.390524 0.390524C0.911223 -0.130175 1.75544 -0.130175 2.27614 0.390524L8 6.11438L13.7239 0.390524C14.2446 -0.130175 15.0888 -0.130175 15.6095 0.390524C16.1302 0.911223 16.1302 1.75544 15.6095 2.27614L9.88562 8L15.6095 13.7239C16.1302 14.2446 16.1302 15.0888 15.6095 15.6095C15.0888 16.1302 14.2446 16.1302 13.7239 15.6095L8 9.88562L2.27614 15.6095C1.75544 16.1302 0.911223 16.1302 0.390524 15.6095C-0.130175 15.0888 -0.130175 14.2446 0.390524 13.7239L6.11438 8L0.390524 2.27614C-0.130175 1.75544 -0.130175 0.911223 0.390524 0.390524Z" fill="#A19E95"></path></svg>
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

JoinModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default JoinModal;
