import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { navigate } from "gatsby"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  Button, Container, OfferingCard, Loader, JoinModal
} from '@components';
import { getPath, getJsonResponse } from '../../api';
import './styles.scss';

const responsive = {
  0: { items: 1 },
  700: { items: 2 },
  920: { items: 3 },
};

const FeaturedDeals = ({
  stage,
  title,
  text,
  label,
  hasBtn
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [offeringsLoading, setOfferingsLoading] = useState(true);
  const [featuredOfferings, setFeaturedOfferings] = useState([]);
  const [displayModal, setModalDisplay] = useState(false);

  const slidePrev = () => {
    const nextIndex = activeIndex - 1;
    if (nextIndex < 0) {
      return false;
    }
    setActiveIndex(activeIndex - 1);
    return true;
  };
  const slideNext = () => {
    const nextIndex = activeIndex + 1;
    if (nextIndex >= featuredOfferings.length) {
      return false;
    }
    setActiveIndex(activeIndex + 1);
    return true;
  };
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  useEffect(() => {
    const endpointPath = getPath(`/api/v1/offerings-featured?stage=${stage}`);
    const config = {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
          'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
          'Content-Type': 'application/json'
      },
    };
    fetch(endpointPath, config)
      .then(getJsonResponse)
      .then((data) => {
        setFeaturedOfferings(data || []);
        setOfferingsLoading(false);
      })
      .catch(() => {
        setOfferingsLoading(false);
      });
  }, [stage]);

  return (
    <Container isSmall background="#fff">
      <div className="FeaturedDeals">
        <div className="header">{title}</div>
        <div className="desc">{text}</div>
        <div className="list-title">{label}</div>
        <div className="list">
          {offeringsLoading ? (
            <div className="LoaderContainer">
              <Loader />
            </div>
          ) : (
            <AliceCarousel
              mouseTracking
              disableDotsControls
              disableButtonsControls
              controlsStrategy="alternate"
              items={featuredOfferings.map(of => <OfferingCard key={of.id} offering={of} />)}
              activeIndex={activeIndex}
              responsive={responsive}
              onSlideChanged={syncActiveIndex}
            />
          )}
        </div>
        {!featuredOfferings.length ? (
          <div className="LoaderContainer">
            No featured deals added
          </div>
        ) : null}
        <div className="actions">
          {hasBtn ? (
            // <Button onClick={() => navigate("/offerings/")}>
            //   View more opportunities
            // </Button>
            <Button onClick={() => setModalDisplay(true)}>
              View more opportunities
            </Button>
          ) : <div />}
          <div className="b-refs-buttons">
            <button className="gallery-control-btn" onClick={slidePrev} disabled={offeringsLoading}>
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L2.41421 5L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893Z" fill="#191919" />
              </svg>
            </button>
            <button className="gallery-control-btn" onClick={slideNext} disabled={offeringsLoading}>
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.292894 9.70711C-0.0976307 9.31658 -0.0976307 8.68342 0.292894 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683417 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292894 9.70711Z" fill="#191919" />
              </svg>
            </button>
          </div>
        </div>
        {displayModal && (
          <JoinModal
            isOpen={displayModal}
            closeCb={() => setModalDisplay(false)}
          />
        )}
      </div>
    </Container>
  );
}

FeaturedDeals.propTypes = {
  stage: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  hasBtn: PropTypes.bool,
};

FeaturedDeals.defaultProps = {
  stage: 'published',
  title: '',
  text: '',
  label: '',
  hasBtn: true,
};

export default FeaturedDeals;
