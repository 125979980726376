import React from 'react';
import { Container, FaqItem } from '@components';
import './styles.scss';

const Faq = () => (
  <Container isSmall background="#F4F4F4">
    <div className="Faq">
      <div className="header">FAQs</div>
      <FaqItem
        title="What are the costs involved for an entrepreneur who is seeking financing?"
        text="The cost of launching a capital raising project depends on the venture's status. To maximize the chance of success, each opportunity will be put through a qualification process. This process takes less than two weeks and concludes with a formal mandate for Kobo Funds. There are fixed costs according to the required fundraising activities, plus a commission."
      />
      <FaqItem
        title="What are the costs for investors?"
        text="No fee is charged to investors, except for specific buy-side agreements."
      />
      <FaqItem
        title="Is there a minimum or maximum investment?"
        text="The minimum investment varies depending on the type of campaign, but usually is set at €100,000."
      />
      <FaqItem
        title="Is the investment made directly in the target company?"
        text="We have opted for the maximum flexibility in terms of investment vehicle, leaving to each issuer the optional use of a special purpose vehicle."
      />
      <FaqItem
        title="What are the risks involved in an investment?"
        text="As with all equity investments, there is a risk of entire investment loss. For this reason, most expert investors allocate only ‘surplus money’ for this type of investment."
      />
      <FaqItem
        title="Which sorts of company can carry out a capital raising project with Kobo Funds?"
        text="Usually startups and small/mid caps are the ideal entities, but we have also managed larger projects with considerable investment sizes."
      />
      <FaqItem
        title="How easy is it to cash in my investment?"
        text="As the digital investment banking market grows, a secondary market for acquired shares is appearing. Currently, unless specific conditions are attached to a particular deal, shares are tied up until a liquidity event, i.e. a listing, a full trade sale or a replacement deal."
      />
      <FaqItem
        title="Are investment opportunities screened?"
        text="Yes. Both Kobo Funds’ advisory board and the Venture Partner in charge analyse investments to ensure full compliance and completeness of information. Guidance from lead professional investors is our signature feature, ensuring that the investment opportunity has been thoroughly vetted."
      />
      <FaqItem
        title="How is my confidential information protected?"
        text="Before starting a mandate, parties agree to the level of confidentiality required. Furthermore, access to the deal room is strictly monitored by the Venture Partner."
      />
      <FaqItem
        title="How does a Venture Partner get assigned to a company?"
        text="Before accepting a mandate, Kobo Funds kicks off a search to identify a suitable qualified Venture Partner who becomes the investment manager responsible for all activities connected to the initiative, including all information flows to investors both pre and post-investment. The Venture Partner is often investing in the company, thus acting as the syndicate leader."
      />
    </div>
  </Container>
);

export default Faq;
