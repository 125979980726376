export const getPath = url => {
  const isBrowser = () => typeof window !== "undefined"
  const hostname = !isBrowser() ? '' : window?.location?.hostname;
  // const config = getConfigData(hostname);
  const isDevelopment = hostname === 'localhost'
  if (isDevelopment) {
    const devURL = `http://localhost:3055${url}`;
    return devURL;
  }
  return `${process.env.ADMIN_APP_URL}${url}`;
};

export const getJsonResponse = response => {
  return response.json()
}

export const getClientAppUrl = () => process.env.CLIENT_APP_URL;
