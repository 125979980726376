import React, { useState } from 'react';
import { Button, Container, JoinModal } from '@components';
import { getClientAppUrl } from '../../api';
import './styles.scss';

const JoinKobofunds = () => {
  // const isBrowser = () => typeof window !== "undefined"
  // const hostname = !isBrowser() ? '' : window?.location?.hostname;
  // const config = getConfigData(hostname);
  const [displayModal, setModalDisplay] = useState(false);
  return (
    <Container>
      <div className="JoinKobofunds">
        <div className="JoinKobofunds-left">
          <h2 className="header">
            Join Kobo Funds investment network
          </h2>
          <div className="actions">
            <Button onClick={() => setModalDisplay(true)}>
              Join
            </Button>
            <a className='Button Button-white' href={getClientAppUrl()} target="_blank" rel="noreferrer noopener">
              Log in
            </a>
          </div>
        </div>
        <div className="JoinKobofunds-right">
          <div className="contact-data">
            <div className="name">
              Kobo Capital Ltd
            </div>
            <div className="content">
              Unit 229, Canalot Studios<br />
              222 Kensal Road, Westbourne Park<br />
              London W10 5BN<br />
              United Kingdom
            </div>
          </div>
          <div className="background-image" />
        </div>
        {displayModal && (
          <JoinModal
            isOpen={displayModal}
            closeCb={() => setModalDisplay(false)}
          />
        )}
      </div>
    </Container>
  );
};

export default JoinKobofunds;
