import React from 'react';
import './styles.scss';

const Hamburger = ({
  isOpen,
  toggleOpen,
}) => {
  return (
    <button
      className={`Hamburger ${isOpen ? 'Hamburger-open' : ''}`}
      onClick={() => toggleOpen(!isOpen)}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default Hamburger;
