/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Header, Footer, JoinKobofunds } from "@components"
import "./layout.css"

const Layout = ({ children }) => {
  // const isBrowser = () => typeof window !== "undefined"
  // const hostname = !isBrowser() ? '' : window?.location?.hostname;
  // const isProduction = hostname === 'kobofunds.com';
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // const func = (l) => {
  //             if (!l) {
  //               window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
  //               window.lintrk.q = []
  //             }
  //             var s = document.getElementsByTagName("script")[0];
  //             var b = document.createElement("script");
  //             b.type = "text/javascript"; b.async = true;
  //             b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  //             s.parentNode.insertBefore(b, s);
  //             }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="LayoutMain">{children}</main>
      <JoinKobofunds />
      <Footer />
      {/* {isProduction && (
        <>
          <script type="text/javascript">
            _linkedin_partner_id = "5498937";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          </script>
          <script type="text/javascript">
            (func)(window.lintrk);
            </script>
            <noscript>
              <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=5498937&fmt=gif" />
            </noscript>
        </>
      )} */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
