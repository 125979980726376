import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from '@components';
import { getPath } from '../../api';
import './styles.scss';

const JoinForm = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const titleFromParams = params.get("title");
  const subTitleFromParams = params.get("subtitle");

  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nameError, setNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShow, setPasswordShow] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [bio, setBio] = useState('');
  const [bioError, setBioError] = useState('');
  const [website, setWebsite] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRolesError, setSelectedRolesError] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const [captchaSubmitted, setCaptchaSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaSubmitted) { return false; }
    if (!email) {
      setEmailError('Email is required');
      return false;
    }
    /* eslint-disable no-useless-escape */
    const emailValid = email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!emailValid) {
      setEmailError('Email is not valid');
      return false;
    }
    if (!firstName || !lastName) {
      setNameError('Full name is required');
      return false;
    }
    if (!password) {
      setPasswordError('Password is required');
      return false;
    }
    if (!bio) {
      setBioError('Professional experience info is required');
      return false;
    }
    if (!selectedRoles.length) {
      setSelectedRolesError('Select at least one role');
      return false;
    }

    setLoading(true);
    const data = {
      user_name: email,
      password,
      bio,
      website,
      first_name: firstName,
      last_name: lastName,
      role_names: selectedRoles,
      app_name: 'client',
    };
    const endpointPath = getPath('/api/v1/register');
    const fetchConfig = {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    };
    fetch(endpointPath, fetchConfig)
      .then((response) => {
        if (!response.ok) {
          // create error object and reject if not a 2xx response code
          let err = new Error("HTTP status code: " + response.status)
          err.response = response
          err.status = response.status
          throw err
      }
        setLoading(false);
        setSubmitted(true);
      })
      .catch(() => {
        setError('Error occured. Check that you have not already used this email');
        setLoading(false);
      });
    return true;
  };

  const handleRoleSelect = (value, name) => {
    setSelectedRolesError('');
    if (value) {
      const list = [...selectedRoles, name];
      setSelectedRoles(list);
    } else {
      const list = selectedRoles.filter(sr => sr !== name);
      setSelectedRoles(list);
    }
  };

  const onChange = (value) => {
    setCaptchaSubmitted(!!value);
  }

  const header = titleFromParams ? titleFromParams : 'Join now';
  const subheader = subTitleFromParams ? subTitleFromParams : 'and invest in high-growth opportunities';

  if (isSubmitted) {
    return (
        <div className="JoinForm success">
          <div className="header">{header}</div>
          <div className="subheader">{subheader}</div>
          <div className="submit-container">
            <div className="text">
              Your request has been sent to the platform administrator. After you have been validated, you will receive full access.
            </div>
            <a href={process.env.CLIENT_APP_URL} target="_blank" rel="noopener noreferrer" className="platform-link">Login to the platform</a>
          </div>
        </div>
    );
  }

  return (
      <div className="JoinForm">
        <div className="header">{header}</div>
        <div className="subheader">{subheader}</div>
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="TextInput">
            <input
              required={true}
              type="email"
              placeholder="Email*"
              onChange={(e) => {
                setEmail(e?.target?.value);
                setEmailError('');
              }}
              value={email}
            />
          </div>
          <div className="FullName">
            <div className="TextInput">
              <input
                required={true}
                type="text"
                placeholder="First name*"
                onChange={(e) => {
                  setFirstName(e?.target?.value);
                  setNameError('');
                }}
                value={firstName}
              />
            </div>
            <div className="TextInput">
              <input
                required={true}
                type="text"
                placeholder="Last name*"
                onChange={(e) => {
                  setLastName(e?.target?.value);
                  setNameError('');
                }}
                value={lastName}
              />
            </div>
          </div>
          <div className="TextInput Password">
            <input
              type={passwordShow ? 'text' : 'password'}
              placeholder="Choose password*"
              required={true}
              onChange={(e) => {
                setPassword(e?.target?.value);
                setPasswordError('');
              }}
              value={password}
            />
            <div className="Checkbox">
              <label>
                <input type="checkbox" name="show-password" id="show-password" checked={passwordShow} onChange={(e) => setPasswordShow(e.target.checked)} />
                <span>Show</span>
              </label>
            </div>
          </div>
          <div className="TextInput">
            <input
              placeholder="Link to a professional website"
              onChange={(e) => {
                setWebsite(e?.target?.value);
              }}
              value={website}
            />
          </div>
          <div className="TextArea">
            <textarea
              placeholder="Tell us a bit about your professional experience.*"
              required={true}
              onChange={(e) => {
                setBio(e?.target?.value);
                setBioError('');
              }}
              value={bio}
            />
          </div>
          <div>
            <ReCAPTCHA
              sitekey="6Lf_ixIeAAAAAK_K90vtGTY9UNQIy58pC15qXZ-4"
              onChange={onChange}
            />
          </div>
          {emailError && <div className="error">{emailError}</div>}
          {nameError && <div className="error">{nameError}</div>}
          {passwordError && <div className="error">{passwordError}</div>}
          {bioError && <div className="error">{bioError}</div>}
          <div className="RolesSelect">
            <div className="roles-header">Select roles:</div>
            <div className="CheckboxInput">
              <input type="checkbox" id="maker" name="maker" checked={selectedRoles.includes('maker')} onChange={(e) => handleRoleSelect(e.target.checked, 'maker')} />
              <label htmlFor="maker">Investor</label>
            </div>
            <div className="CheckboxInput">
              <input type="checkbox" id="taker" name="taker" checked={selectedRoles.includes('taker')} onChange={(e) => handleRoleSelect(e.target.checked, 'taker')} />
              <label htmlFor="taker">Entrepreneur</label>
            </div>
            <div className="CheckboxInput">
              <input type="checkbox" id="negotiator" name="negotiator" checked={selectedRoles.includes('negotiator')} onChange={(e) => handleRoleSelect(e.target.checked, 'negotiator')} />
              <label htmlFor="negotiator">Venture partner</label>
            </div>
          </div>
          {selectedRolesError && <div className="error">{selectedRolesError}</div>}
          <div className="button-container">
            <Button onClick={handleSubmit} type="submit" disabled={isLoading || !captchaSubmitted}>
              Submit
            </Button>
          </div>
          <div className="acknowledge-container">
            <p>By clicking Submit you agree to Kobo Funds platform <a target="_blank" rel="noreferrer noopener" href="/terms" data-scroll>Terms & Conditions</a>, <a target="_blank" rel="noreferrer noopener" href="/privacy" data-scroll>Privacy policy</a> and <a target="_blank" rel="noreferrer noopener" href="/nda" data-scroll>Non disclosure agreement</a> of all the Confidential Information received through the platform.</p>
          </div>
          {error && <div className="error">{error}</div>}
        </form>
      </div>
  );
};

export default JoinForm;
