import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@components';
import './styles.scss';

const PageTitle = ({ title }) => (
  <Container>
    <div className="PageTitle">
      <h1 className="title">{title}</h1>
      <div className="background-image" />
    </div>
  </Container>
);

PageTitle.propTypes = {
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  title: 'Title',
};

export default PageTitle;
