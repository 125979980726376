import React from 'react';
import './styles.scss';

const Loader = () => (
  <div className="Loader lds-ellipsis">
    <div/>
    <div/>
    <div/>
    <div/>
  </div>
);

export default Loader;
